<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"

  import { db } from "@/db"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { sum, isOnline } from "@/utils/Utilities"
  import { gettext } from "@/utils/Translation"
  import {
    getSiteByCmiId,
    getCurrentReferralsForSite,
    getPendingGrowthAssessmentsForSite,
    getPendingFeedingScreeningsForSite,
    getPendingAnemiaAssessmentsForSite,
    getPendingDevelopmentalScreeningsForSite,
  } from "@/services/Site"
  import { AnemiaAssessment } from "@/models/AnemiaAssessment"
  import { FeedingScreening } from "@/models/Feeding"
  import { GrowthAssessment } from "@/models/GrowthAssessment"
  import { DevelopmentalScreening } from "@/models/DevelopmentalScreening"

  import PageLoading from "@/components/PageLoading.vue"
  import ClinicalReportReferralsSection from "@/views/sites/ClinicalReportReferralsSection.vue"

  const { $gettext } = gettext
  const route = useRoute()
  const siteCmiId = parseInt(route.params.siteCmiId as string)

  const site = ref(null)
  const referrals = ref(null)
  const lastUpdated = ref(null)
  const pageReady = ref(false)

  function mungeReferrals(assessments: { anemia: Array<AnemiaAssessment>, growth: Array<GrowthAssessment>, feedingScreening: Array<FeedingScreening>, developmentalScreening: Array<DevelopmentalScreening> }) {
    const results = {}
    if (assessments.anemia?.length) {
      results["anemia"] = assessments.anemia.map(assessment => {
        return {
          id: assessment.id,
          childName: assessment.child.fullName,
          reason: assessment.referrals,
          referralDate: assessment.referralDate.toLocaleDateString(),
          carePlanParams: { name: "ChildCarePlan", params: { childId: assessment.childId }},
          recsLinkParams: { name: "AnemiaRecommendations", params: { childId: assessment.childId, assessmentId: assessment.id }},
        }
      })
    }
    if (assessments.growth?.length) {
      results["growth"] = assessments.growth.map(assessment => {
        return {
          id: assessment.id,
          childName: assessment.child.fullName,
          reason: assessment.referrals,
          referralDate: assessment.referralDate.toLocaleDateString(),
          carePlanParams: { name: "ChildCarePlan", params: { childId: assessment.childId }},
          recsLinkParams: { name: "GrowthRecommendations", params: { childId: assessment.childId, assessmentId: assessment.id }},
        }
      })
    }
    if (assessments.feedingScreening?.length) {
      results["feedingScreening"] = assessments.feedingScreening.map(assessment => {
        return {
          id: assessment.id,
          childName: assessment.child.fullName,
          reason: assessment.referrals,
          referralDate: assessment.referralDate.toLocaleDateString(),
          carePlanParams: { name: "ChildCarePlan", params: { childId: assessment.childId }},
          recsLinkParams: { name: "FeedingScreeningRecommendations", params: { childId: assessment.childId, assessmentId: assessment.id }},
        }
      })
    }
    if (assessments.developmentalScreening?.length) {
      results["developmentalScreening"] = assessments.developmentalScreening.map(assessment => {
        return {
          id: assessment.id,
          childName: assessment.child.fullName,
          reason: assessment.getReferrals(),
          referralDate: assessment.referralDate.toLocaleDateString(),
          carePlanParams: { name: "ChildCarePlan", params: { childId: assessment.childId }},
          recsLinkParams: { name: "DevelopmentalScreeningRecommendations", params: { childId: assessment.childId, assessmentId: assessment.id }},
        }
      })
    }
    return results
  }

  async function setLastUpdatedStats() {
    const results = {
      message: "",
      shouldDownload: false,
      danger: false,
    }
    if (isOnline()) {
      const promises = []
      if (site.value.growthEnabled) {
        promises.push(getPendingGrowthAssessmentsForSite(site.value, { count: true }))
      }
      if (site.value.isFeedingScreeningEnabled) {
        promises.push(getPendingFeedingScreeningsForSite(site.value, { count: true }))
      }
      if (site.value.anemiaEnabled) {
        promises.push(getPendingAnemiaAssessmentsForSite(site.value, { count: true }))
      }
      if (site.value.earlyidEnabled) {
        promises.push(getPendingDevelopmentalScreeningsForSite(site.value, { count: true }))
      }
      const pendingDownloadCount = sum(await Promise.all(promises))
      if (pendingDownloadCount) {
        results["message"] = $gettext("There are assessments available that could be included in this report. (%{number})", { number: pendingDownloadCount} )
        results["shouldDownload"] = true
        results["danger"] = true
      }
      else {
        results["message"] = $gettext("This report is up-to-date as of %{date}", { date: new Date().toLocaleString() })
      }
    }
    else {
      const promises = []
      if (site.value.growthEnabled) {
        promises.push(db.lastUpdated.get({ type: "siteGrowthAssessments", localItemId: site.value.id }))
      }
      if (site.value.anemiaEnabled) {
        promises.push(db.lastUpdated.get({ type: "siteAnemiaAssessments", localItemId: site.value.id }))
      }
      if (site.value.earlyidEnabled) {
        promises.push(db.lastUpdated.get({ type: "siteDevelopmentalScreenings", localItemId: site.value.id }))
      }
      const lastUpdatedDates = await Promise.all(promises)
      if (lastUpdatedDates.every(d => d)) {
        const earliestDate = Math.min(lastUpdatedDates)
        results["message"] = $gettext("This report includes assessments up to %{date}. When you are online, please visit the \"Prepare for offline usage\" page to download assessments for this site.", { date: earliestDate.toLocaleDateString() })
        results["danger"] = true
      }
      else {
        results["message"] = $gettext("There could be assessments available that would be included in this report. When you are online, please visit the \"Prepare for offline usage\" page to download assessments for this site.")
        results["danger"] = true
      }
    }
    lastUpdated.value = results
  }

  async function getData() {
    site.value = await getSiteByCmiId(siteCmiId)
    setCurrentViewContext(site.value, "site")
    // getCurrentReferralsForSite returns an obj mapping module id ("developmentalScreening")
    // to an array of *assessments*.
    referrals.value = mungeReferrals(await getCurrentReferralsForSite(site.value))
    pageReady.value = true
    setLastUpdatedStats()
  }

  getData()

</script>
<template>
  <article>
    <div
      class="super-title"
      aria-hidden="true"
    >
      {{ $gettext("Clinical Reports") }}
    </div>
    <h1 class="title">
      {{ $gettext("Clinical Report: Referrals") }}
    </h1>
    <template v-if="pageReady">

      <div
        v-if="lastUpdated"
        :class="['message', 'is-small', 'mb-4', lastUpdated.danger ? 'is-danger': 'is-success']"
      >
        <div
          class="message-body"
        >
          <div
            class="columns"
          >
            <div class="column">
              <div
                v-if="lastUpdated.danger"
                class="bang"
              >
                !
              </div>
              {{ lastUpdated.message }}
            </div>
            <div
              class="column is-flex-grow-0"
              v-if="lastUpdated.shouldDownload"
            >
              <router-link
                :to="{ name: 'PrepareForOffline'}"
                class="button is-small is-fullwidth"
              >
                {{ $gettext("Download now") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <ClinicalReportReferralsSection
        v-if="referrals.anemia"
        :section-title="$gettext('Referrals related to anemia')"
        :referrals="referrals.anemia"
      />
      <ClinicalReportReferralsSection
        v-if="referrals.growth"
        :section-title="$gettext('Referrals related to growth')"
        :referrals="referrals.growth"
      />
      <ClinicalReportReferralsSection
        v-if="referrals.feedingScreening"
        :section-title="$gettext('Referrals related to feeding')"
        :referrals="referrals.feedingScreening"
      />
      <ClinicalReportReferralsSection
        v-if="referrals.developmentalScreening"
        :section-title="$gettext('Referrals related to development')"
        :referrals="referrals.developmentalScreening"
      />
    </template>
    <PageLoading v-else />
  </article>
</template>
<style lang="scss" scoped>
  .button.is-small {
    margin-top: -3px;
  }
  .bang {
    border-radius: 50%;
    color: rgb(204, 0, 41);
    border: 2px solid rgb(204, 0, 41);
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.2;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    vertical-align: bottom;
  }
</style>
