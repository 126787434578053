<script setup>
import { useRoute } from "vue-router"

import { getAccountAndSiteRoleSchema } from "@/schemas/Account"
import { createSiteAccount, getSiteByCmiId } from "@/services/Site"
import { setCurrentViewContext } from "@/utils/GlobalState"
import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"

const route = useRoute()
const siteCmiId = parseInt(route.params.siteCmiId)
const uploadFunc = (data) => createSiteAccount(siteCmiId, data)
const successRoute = { name: "SiteAccounts", params: { siteCmiId } }

async function getData() {
  const site = await getSiteByCmiId(siteCmiId)
  setCurrentViewContext(site, "site")
}
getData()
</script>

<template>
  <article>
    <OnlineRequiredForm
      :page-title="$gettext('Add an Account')"
      :schema="getAccountAndSiteRoleSchema()"
      :upload-func="uploadFunc"
      :success-route="successRoute"
    />
  </article>
</template>
