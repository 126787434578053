<script setup>
import { useRouter, useRoute } from "vue-router"
import { setCurrentViewContext } from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import {
  getSiteByCmiId,
  getBestPracticeTypeCandidatesForSite,
  updateSiteSettings
} from "@/services/Site"
import { getSiteSettingsSchema } from "@/schemas/Site"
import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"

const { $gettext } = gettext
const router = useRouter()
const route = useRoute()
const siteCmiId = parseInt(route.params.siteCmiId)
const pushSuccessRouteFunc = () => router.replace({ name: "SiteManage", params: { siteCmiId } })
const siteSettingsSchema = getSiteSettingsSchema()

// Customize the schema to exclude best practice assessment types not appropriate
// for this site based on their setting for typesOfServices
function getSchemaFunc(site) {
  const schema = []
  const bpCandidates = getBestPracticeTypeCandidatesForSite(site)
  for (let i=0; i<siteSettingsSchema.length; i++) {
    const field = siteSettingsSchema[i]
    if (field.name == "isSiteMealtimeBestPracticeAssessmentEnabled" && !bpCandidates.includes("site")) {
      continue
    }
    if (field.name == "isChildMealtimeBestPracticeAssessmentEnabled" && !bpCandidates.includes("child")) {
      continue
    }
    // Don't allow training mode to be *disabled* through this form (bc it requires warning about purges).
    if (field.name == "inTrainingMode" && site.inTrainingMode) {
      continue
    }
    schema.push(field)
  }
  return schema
}

function uploadFunc(data) {
  data.cmiId = siteCmiId
  return updateSiteSettings(data)
}

async function formDataRetrievalFunc() {
  const site = await getSiteByCmiId(siteCmiId, { fromServer: true })
  setCurrentViewContext(site, "site")
  return site
}
</script>

<template>
  <article>
    <OnlineRequiredForm
      :page-title="$gettext('Site Settings')"
      :get-schema-func="getSchemaFunc"
      :form-data-retrieval-func="formDataRetrievalFunc"
      :upload-func="uploadFunc"
      :push-success-route-func="pushSuccessRouteFunc"
    />
  </article>
</template>
